:root {
  --clr-neutral-900: hsl(207, 19%, 9%);
  --clr-neutral-100: hsl(0, 0%, 100%);
  --clr-accent-400: hsl(311, 20%, 63%);
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

/* body {
  display: grid;
  min-height: 100vh;
  place-items: center;
  line-height: 1.6;
  background: var(--clr-neutral-900);
  font-family: sans-serif;
  padding-right: 15em;
} */

.card {
  color: var(--clr-neutral-100);
  /* background-image: url(https://media.contentapi.ea.com/content/dam/eacom/images/2020/09/ea-featured-image-ea-desktop-beta.jpg.adapt.crop191x100.1200w.jpg); */
  /* background-image: url(../assets/todo.png); */
  background-size: cover;
  padding: 10rem 0 0;
  max-width: 30rem;
  border-radius: 0.5rem;
  overflow: hidden;
  transition: transform 500ms ease;
  display: flex;
  flex-direction: column;
}

.card h2 {
  margin-bottom: 1rem;
}

.card a {
  margin-top: 1rem;
}

.todo {
  background-image: url(../../public/assets/todo.png);
  background-size: cover;
}

.b2b {
  background-image: url("../../public/assets/b2b.png");
  background-size: cover;
}

.recepten {
  background-image: url("../../public/assets/receptenweb.png");
  background-size: cover;
}

.card:hover,
.card:focus-within {
  transform: scale(1.05);
}

.card-content {
  --padding: 1.5rem;
  padding: var(--padding);
  background: linear-gradient(
    hsl(0 0% 0% / 0),
    hsl(20 0% 0% / 0.3) 20%,
    hsl(0 0% 0% / 1)
  );
  margin-top: auto; /* Ensure card-content is pushed to the bottom */
}

.card-title {
  position: relative;
  width: max-content;
}

.card:hover .card-title::after,
.card:focus-within .card-title::after {
  transform: scaleX(1);
}

.card-title::after {
  content: "";
  position: absolute;
  height: 4px;
  width: calc(100% + var(--padding));
  left: calc(var(--padding) * -1);
  bottom: -2px;
  background: var(--clr-accent-400);
  transform-origin: left;
  transition: transform 500ms ease;
}

@media (hover) {
  .card-content {
    transform: translateY(65%);
    transition: transform 500ms ease;
  }

  .card-content > *:not(.card-title) {
    opacity: 0;
    transition: opacity 500ms linear;
  }

  .card:hover .card-content > *:not(.card-title),
  .card:focus-within .card-content > *:not(.card-title) {
    opacity: 1;
    transition-delay: 700ms;
  }

  .card:hover .card-content,
  .card:focus-within .card-content {
    transform: translateY(0);
    transition-delay: 500ms;
  }

  .card:focus-within .card-content {
    transition-duration: 0ms;
  }

  .card-title::after {
    transform: scaleX(0);
  }
}

.button {
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  color: var(--clr-neutral-900);
  background-color: var(--clr-accent-400);
  padding: 0.5em 1.25em;
  border-radius: 0.25rem;
}

.button:hover,
.button:focus {
  background-color: var(--clr-neutral-100);
}

.card-body {
  color: rgb(255, 255, 255/0.85);
}

@media (prefers-reduced-motion: reduce) {
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
    transition-delay: 0ms !important;
  }
}
