/* App.css */
.ghs-link {
    position: relative;
    display: inline-block;
    transition: color 0.3s ease;
  }
  
.ghs-link::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #8aadf4;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
  
.ghs-link:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
}


.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #24273a; 
  z-index: 1000; 
}

