:root {
  --cut: 50px;
}

@media (max-width: 890px) {
  .flex-container {
    flex-direction: column;
    align-items: center;
  }

  .flex-container p {
    max-width: 20rem;
  }

  .flex-text {
    margin-left: 0;
    text-align: center;
  }

  .name {
    align-items: center; 
  }
}

.hero-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full height viewport */
}

.flex-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  /* Adjust other styles as needed */
}

.cursor-default {
  cursor: default;
}

.transition-all {
  transition: all 0.5s;
}

* {
  padding: 0;
  box-sizing: border-box !important;
}

html, body {
  height: 100%;
}

body {
  display: table;
  width: 100%;
  height: 100%;
  background-color: #171717;
  color: #000;
  line-height: 1.6;
  position: relative;
  font-family: sans-serif;
  /* overflow: hidden; */
}

.lines {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  margin: auto;
  width: 90vw;
} 

.line {
  position: absolute;
  width: 1px;
  height: 100%;
  top: 0;
  left: 50%;
  background: #24273a;
  overflow: hidden;
}

.line::after {
  content: '';
  display: block;
  position: absolute;
  height: 15vh;
  width: 100%;
  top: -50%;
  left: 0;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #ffffff 75%, #ffffff 100%);
  animation: drop 7s 0s infinite;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
}

.line:nth-child(1) {
  margin-left: -25%;
}

.line:nth-child(1)::after {
  animation-delay: 2s;
}

.line:nth-child(3) {
  margin-left: 25%;
}

.line:nth-child(3)::after {
  animation-delay: 2.5s;
}
    
@keyframes drop {
  0% {
    top: -50%;
  }
  100% {
    top: 110%;
  }
}
