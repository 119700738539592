/* Globale stijl */
.overview-container {
    display: flex;
    flex-direction: column; 
    justify-content: center; 
    align-items: center; 
    min-height: 100vh; 
  }
  
  @media (max-width: 1174px) {
    .overview-container {
      flex-direction: column;
      align-items: center;
    }
  }
  