body {
    --background-color: #18181B;
    --text-color: #A1A1AA;
  
    --card-background-color: rgba(255, 255, 255, .015);
    --card-border-color: rgba(255, 255, 255, 0.1);
    --card-box-shadow-1: rgba(0, 0, 0, 0.05);
    --card-box-shadow-1-y: 3px;
    --card-box-shadow-1-blur: 6px;
    --card-box-shadow-2: rgba(0, 0, 0, 0.1);
    --card-box-shadow-2-y: 8px;
    --card-box-shadow-2-blur: 15px;
    --card-label-color: #FFFFFF;
    --card-icon-color: #D4D4D8;
    --card-icon-background-color: rgba(255, 255, 255, 0.08);
    --card-icon-border-color: rgba(255, 255, 255, 0.12);
    --card-shine-opacity: .1;
    --card-shine-gradient: conic-gradient(from 205deg at 50% 50%, rgba(16, 185, 129, 0) 0deg, #10B981 25deg, rgba(52, 211, 153, 0.18) 295deg, rgba(16, 185, 129, 0) 360deg);
    --card-line-color: #2A2B2C;
    --card-tile-color: rgba(16, 185, 129, 0.05);
  
    --card-hover-border-color: rgba(255, 255, 255, 0.2);
    --card-hover-box-shadow-1: rgba(0, 0, 0, 0.04);
    --card-hover-box-shadow-1-y: 5px;
    --card-hover-box-shadow-1-blur: 10px;
    --card-hover-box-shadow-2: rgba(0, 0, 0, 0.3);
    --card-hover-box-shadow-2-y: 15px;
    --card-hover-box-shadow-2-blur: 25px;
    --card-hover-icon-color: #34D399;
    --card-hover-icon-background-color: rgba(52, 211, 153, 0.1);
    --card-hover-icon-border-color: rgba(52, 211, 153, 0.2);
  
    --blur-opacity: .01;
  
    &.light {
      --background-color: #FAFAFA;
      --text-color: #52525B;
  
      --card-background-color: transparent;
      --card-border-color: rgba(24, 24, 27, 0.08);
      --card-box-shadow-1: rgba(24, 24, 27, 0.02);
      --card-box-shadow-1-y: 3px;
      --card-box-shadow-1-blur: 6px;
      --card-box-shadow-2: rgba(24, 24, 27, 0.04);
      --card-box-shadow-2-y: 2px;
      --card-box-shadow-2-blur: 7px;
      --card-label-color: #18181B;
      --card-icon-color: #18181B;
      --card-icon-background-color: rgba(24, 24, 27, 0.04);
      --card-icon-border-color: rgba(24, 24, 27, 0.1);
      --card-shine-opacity: .3;
      --card-shine-gradient: conic-gradient(from 225deg at 50% 50%, rgba(16, 185, 129, 0) 0deg, #10B981 25deg, #EDFAF6 285deg, #FFFFFF 345deg, rgba(16, 185, 129, 0) 360deg);
      --card-line-color: #E9E9E7;
      --card-tile-color: rgba(16, 185, 129, 0.08);
  
      --card-hover-border-color: rgba(24, 24, 27, 0.15);
      --card-hover-box-shadow-1: rgba(24, 24, 27, 0.05);
      --card-hover-box-shadow-1-y: 3px;
      --card-hover-box-shadow-1-blur: 6px;
      --card-hover-box-shadow-2: rgba(24, 24, 27, 0.1);
      --card-hover-box-shadow-2-y: 8px;
      --card-hover-box-shadow-2-blur: 15px;
      --card-hover-icon-color: #18181B;
      --card-hover-icon-background-color: rgba(24, 24, 27, 0.04);
      --card-hover-icon-border-color: rgba(24, 24, 27, 0.34);
  
      --blur-opacity: .1;
    }
  
    &.toggle .grid * {
      transition-duration: 0s !important;
    }
}


.card {
    background-color: var(--background-color);
    box-shadow: 0px var(--card-box-shadow-1-y) var(--card-box-shadow-1-blur) var(--card-box-shadow-1), 0px var(--card-box-shadow-2-y) var(--card-box-shadow-2-blur) var(--card-box-shadow-2), 0 0 0 1px var(--card-border-color);
    /* padding: 56px 16px 16px 16px; */
    border-radius: 15px;
    cursor: pointer;
    position: relative;
    transition: box-shadow .25s;
  
    &::before {
      content: '';
      position: absolute;
      inset: 0;
      border-radius: 15px;
      background-color: var(--card-background-color);
    }
  
    .icon {
      z-index: 2;
      position: relative;
      display: table;
      padding: 8px;
  
      &::after {
        content: '';
        position: absolute;
        inset: 4.5px;
        border-radius: 50%;
        background-color: var(--card-icon-background-color);
        border: 1px solid var(--card-icon-border-color);
        backdrop-filter: blur(2px);
        transition: background-color .25s, border-color .25s;
      }
  
      svg {
        position: relative;
        z-index: 1;
        display: block;
        width: 24px;
        height: 24px;
        transform: translateZ(0);
        color: var(--card-icon-color);
        transition: color .25s;
      }
    }
  
    h4 {
      z-index: 2;
      position: relative;
      margin: 12px 0 4px 0;
      font-family: inherit;
      font-weight: 600;
      font-size: 14px;
      line-height: 2;
      color: var(--card-label-color);
    }
  
    p {
      z-index: 2;
      position: relative;

      font-size: 14px;
      line-height: 1.7;
      color: var(--text-color);
    }
  
    .shine {
      border-radius: inherit;
      position: absolute;
      inset: 0;
      z-index: 1;
      overflow: hidden;
      opacity: 0;
      transition: opacity .5s;
  
      &:before {
        content: '';
        width: 150%;
        padding-bottom: 150%;
        border-radius: 50%;
        position: absolute;
        left: 50%;
        bottom: 55%;
        filter: blur(35px);
        opacity: var(--card-shine-opacity);
        transform: translateX(-50%);
        background-image: var(--card-shine-gradient);
      }
    }
  
    .background {
      border-radius: inherit;
      position: absolute;
      inset: 0;
      overflow: hidden;
      -webkit-mask-image: radial-gradient(circle at 60% 5%, black 0%, black 15%, transparent 60%);
      mask-image: radial-gradient(circle at 60% 5%, black 0%, black 15%, transparent 60%);
  
      .tiles {
        opacity: 0;
        transition: opacity .25s;
  
        .tile {
          position: absolute;
          background-color: var(--card-tile-color);
          animation-duration: 8s;
          animation-iteration-count: infinite;
          opacity: 0;
  
          &.tile-4,
          &.tile-6,
          &.tile-10 {
            animation-delay: -2s;
          }
  
          &.tile-3,
          &.tile-5,
          &.tile-8 {
            animation-delay: -4s;
          }
  
          &.tile-2,
          &.tile-9 {
            animation-delay: -6s;
          }
  
          &.tile-1 {
            top: 0;
            left: 0;
            height: 10%;
            width: 22.5%;
          }
  
          &.tile-2 {
            top: 0;
            left: 22.5%;
            height: 10%;
            width: 27.5%;
          }
  
          &.tile-3 {
            top: 0;
            left: 50%;
            height: 10%;
            width: 27.5%;
          }
  
          &.tile-4 {
            top: 0;
            left: 77.5%;
            height: 10%;
            width: 22.5%;
          }
  
          &.tile-5 {
            top: 10%;
            left: 0;
            height: 22.5%;
            width: 22.5%;
          }
  
          &.tile-6 {
            top: 10%;
            left: 22.5%;
            height: 22.5%;
            width: 27.5%;
          }
  
          &.tile-7 {
            top: 10%;
            left: 50%;
            height: 22.5%;
            width: 27.5%;
          }
  
          &.tile-8 {
            top: 10%;
            left: 77.5%;
            height: 22.5%;
            width: 22.5%;
          }
  
          &.tile-9 {
            top: 32.5%;
            left: 50%;
            height: 22.5%;
            width: 27.5%;
          }
  
          &.tile-10 {
            top: 32.5%;
            left: 77.5%;
            height: 22.5%;
            width: 22.5%;
          }
        }
      }
  
      @keyframes tile {
  
        0%,
        12.5%,
        100% {
          opacity: 1;
        }
  
        25%,
        82.5% {
          opacity: 0;
        }
      }
  
      .line {
        position: absolute;
        inset: 0;
        opacity: 0;
        transition: opacity .35s;
  
        &:before,
        &:after {
          content: '';
          position: absolute;
          background-color: var(--card-line-color);
          transition: transform .35s;
        }
  
        &:before {
          left: 0;
          right: 0;
          height: 1px;
          transform-origin: 0 50%;
          transform: scaleX(0);
        }
  
        &:after {
          top: 0;
          bottom: 0;
          width: 1px;
          transform-origin: 50% 0;
          transform: scaleY(0);
        }
  
        &.line-1 {
          &:before {
            top: 10%;
          }
  
          &:after {
            left: 22.5%;
          }
  
          &:before,
          &:after {
            transition-delay: .3s;
          }
        }
  
        &.line-2 {
          &:before {
            top: 32.5%;
          }
  
          &:after {
            left: 50%;
          }
  
          &:before,
          &:after {
            transition-delay: .15s;
          }
        }
  
        &.line-3 {
          &:before {
            top: 55%;
          }
  
          &:after {
            right: 22.5%;
          }
        }
      }
    }
  
    &:hover {
      box-shadow: 0px 3px 6px var(--card-hover-box-shadow-1), 0px var(--card-hover-box-shadow-2-y) var(--card-hover-box-shadow-2-blur) var(--card-hover-box-shadow-2), 0 0 0 1px var(--card-hover-border-color);
  
      .icon {
        &::after {
          background-color: var(--card-hover-icon-background-color);
          border-color: var(--card-hover-icon-border-color);
        }
  
        svg {
          color: var(--card-hover-icon-color);
        }
      }
  
      .shine {
        opacity: 1;
        transition-duration: .5s;
        transition-delay: 0s;
      }
  
      .background {
  
        .tiles {
          opacity: 1;
          transition-delay: .25s;
  
          .tile {
            animation-name: tile;
          }
        }
  
        .line {
          opacity: 1;
          transition-duration: .15s;
  
          &:before {
            transform: scaleX(1);
          }
  
          &:after {
            transform: scaleY(1);
          }
  
          &.line-1 {
  
            &:before,
            &:after {
              transition-delay: .0s;
            }
          }
  
          &.line-2 {
  
            &:before,
            &:after {
              transition-delay: .15s;
            }
          }
  
          &.line-3 {
  
            &:before,
            &:after {
              transition-delay: .3s;
            }
          }
        }
      }
    }
  }
